import React from 'react'
import styled from 'styled-components'
// Components
import ArrowSVG from '../svgs/arrow.svg'

export default function Schedule() {
  return (
    <ScheduleWrapper>
      <p className="title" id="schedule">
        PIDE TU CITA
      </p>
      <p className="description">
        Para coordinar una cita en un horario que funcione para los dos, tengo
        un calendario interactivo.
      </p>
      <div className="container">
        <a
          href="https://psiconfort.as.me/?utm_source=landing_page&utm_medium=last_cta&utm_campaign=landing_carlos"
          className="button"
        >
          <ArrowSVG />
          <p>Reserva Ya</p>
        </a>
      </div>
    </ScheduleWrapper>
  )
}

const ScheduleWrapper = styled.section`
  background: #dedede;
  padding: 4rem 8%;

  @media (max-width: 745px) {
    padding: 2rem 2%;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 5rem;
    width: 80%;

    @media (max-width: 900px) {
      width: 92%;
    }

    @media (max-width: 650px) {
      padding: 0;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      margin-top: 0rem;
      margin-bottom: 1rem;
    }

    .button {
      position: relative;
      cursor: pointer;
      background: transparent;
      border: none;

      @media (max-width: 600px) {
        margin-top: 3rem;
      }

      span {
        display: block;
        width: 25px;
        top: 32%;
        left: 20%;
        position: absolute;
      }

      @media (hover: hover) {
        &:hover {
          p {
            letter-spacing: 3px;
          }

          path {
            opacity: 0.8;
          }
        }
      }

      svg {
        width: 30rem;
        height: auto;

        path {
          transition: 0.3s;
        }
      }

      p {
        position: absolute;
        font-family: 'Kinglet';
        font-size: 4.6rem;
        color: #524c4c;
        top: 45%;
        left: 15%;
        transform: translateY(-50%);
        transition: 0.3s;
      }
    }
  }

  p.title {
    font-size: 10rem;
    font-family: 'Happy Cramper';
    color: #d4899b;
    text-shadow: 0.3rem 0.3rem 0.3rem #c85a6c;
    text-align: center;

    @media (max-width: 1080px) {
      font-size: 8rem;
    }
  }

  p.description {
    font-size: 2.2rem;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    margin-top: 2rem;

    @media (max-width: 500px) {
      width: 80%;
    }
  }
`
