import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {useLocation} from '@reach/router'

const siteMetadata = {
  defaultTitle: `Consultas y Orientación Psicológica Online`,
  titleTemplate: 'Psiconfort · %s',
  siteUrl: 'https://psiconfort.com',
  defaultImage: '/images/og-image.jpg',
  defaultDescription: `La distancia ya no es una barrera. ¡ Te acompaño en tu proceso psicológico! Inicia tu terapia 100% online de la mano de Psiconfort y desde cualquier país del mundo.`,
}

const SEO = ({title, description, image, article}) => {
  const {pathname} = useLocation()

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.image && <meta property="og:image:height" content="1257" />}
      {seo.image && <meta property="og:image:width" content="2400" />}

      <meta name="twitter:card" content="summary_large_image" />

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
