// React
import React from 'react'
// Components
import SEO from '../components/SEO'
import Navbar from '../components/Navbar'
import Home from '../components/Home'
import About from '../components/About'
import Psiconfort from '../components/Psiconfort'
import OnlineTherapy from '../components/OnlineTherapy'
import Schedule from '../components/Schedule'
import Footer from '../components/Footer'
// Context
import {CountryContext} from '../providers/country.provider'

export default function IndexPage() {
  const countryContext = React.useContext(CountryContext)

  return (
    <>
      <SEO />
      <Navbar {...countryContext} />
      <Home />
      <About />
      <Psiconfort />
      <OnlineTherapy />
      <Schedule {...countryContext} />
      <Footer />
    </>
  )
}
