import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'
import InstagramLayoutSVG from '../svgs/ig_layout.svg'

export default function Psiconfort() {
  const {crear, clip, fomentar, insta, line, proporcionar} = useStaticQuery(
    query,
  )

  const photos = insta.edges.slice(0, 3).map(({node: {id, localFile}}) => ({
    id,
    url: `https://www.instagram.com/p${id}`,
    img: localFile,
  }))

  return (
    <PsiconfortWrapper>
      <div className="title" id="psiconfort">
        <p>PSICONFORT</p>
        <div className="line-container">
          <Img fluid={line.childImageSharp.fluid} alt="Línea" />
        </div>
      </div>
      <div className="instagram-pics">
        {photos.map(pic => (
          <div className="instagram-pics__pic" key={pic.id}>
            <a href={pic.url} target="_blank" rel="noopener noreferrer">
              <div className="img-container">
                <Img
                  fluid={pic.img.childImageSharp.fluid}
                  alt="Post del Instagram de Psiconfort"
                />
              </div>
              <InstagramLayoutSVG />
            </a>
          </div>
        ))}
      </div>
      <div className="goals">
        <h2 className="title">objetivos de psiconfort</h2>
        <div className="container">
          <div className="clip-img-container">
            <Img fluid={clip.childImageSharp.fluid} alt="Clip" />
          </div>
          <div className="clip-img-container">
            <Img fluid={clip.childImageSharp.fluid} alt="Clip" />
          </div>
          <div className="goal">
            <div className="goal-img-container">
              <Img fluid={fomentar.childImageSharp.fluid} alt="Mapamundi" />
            </div>
            <p>fomentar la cultura psicológica a lo largo del mundo</p>
          </div>
          <div className="goal">
            <div className="goal-img-container">
              <Img fluid={proporcionar.childImageSharp.fluid} alt="Reloj" />
            </div>
            <p>
              proporcionar un espacio de confort y reflexión disponible 24/7
            </p>
          </div>
          <div className="goal">
            <div className="goal-img-container">
              <Img fluid={crear.childImageSharp.fluid} alt="Pulgar Arriba" />
            </div>
            <p>
              crear una comunidad positiva y comprometida con el bienestar
              emocional
            </p>
          </div>
        </div>
      </div>
    </PsiconfortWrapper>
  )
}

const PsiconfortWrapper = styled.section`
  background: #dedede;
  padding: 5rem 8rem;
  overflow: hidden;

  @media (max-width: 600px) {
    padding: 3rem 4rem;
  }

  .goals {
    margin-top: 5rem;

    h2.title {
      font-family: 'Kinglet';
      font-size: 6rem;
      margin: 0 auto;
      font-weight: 100;
      width: fit-content;
      color: #3a3737;
      text-align: center;
    }

    .container {
      position: relative;
      margin-top: 2rem;
      border-radius: 2rem;
      background: #faeaea;
      display: flex;
      justify-content: space-around;
      padding: 3rem;

      @media (max-width: 550px) {
        flex-direction: column;
      }

      .clip-img-container {
        position: absolute;
        top: 25%;
        width: 9rem;

        &:nth-child(1) {
          transform: rotate(180deg);
          left: -6rem;
        }

        &:nth-child(2) {
          right: -6rem;

          @media (max-width: 550px) {
            top: 65%;
          }
        }
      }

      .goal {
        width: 26%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 550px) {
          width: 70%;
          margin: 0 auto;

          &:not(:last-child) {
            margin-bottom: 4rem;
          }
        }

        .goal-img-container {
          width: 13rem;

          @media (max-width: 550px) {
            width: 20rem;
          }
        }

        p {
          text-align: center;
          font-family: 'Kinglet';
          font-size: 2rem;
          margin-top: 2rem;
        }
      }
    }
  }

  .instagram-pics {
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    &__pic {
      width: 26%;
      position: relative;
      transition: 0.3s;
      cursor: pointer;

      @media (max-width: 700px) {
        width: 30%;
      }

      @media (max-width: 500px) {
        width: 88%;
        margin: 0 auto;

        &:not(:last-child) {
          margin-bottom: 4rem;
        }
      }

      &:hover {
        transform: scale(1.03);
      }

      @media (hover: none) {
        &:active {
          transform: scale(1.03);
        }
      }

      svg {
        position: relative;
        width: 100%;
        height: auto;
        z-index: 30;

        path {
          z-index: 30;
        }
      }

      & > img,
      .img-container {
        position: absolute;
        left: 49.7%;
        bottom: 15.1%;
        transform: translateX(-50%);
        width: 82%;
        object-fit: cover;
        z-index: 29;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 10rem;
      font-family: 'Happy Cramper';
      color: #d4899b;
      text-shadow: 0.3rem 0.3rem 0.3rem #c85a6c;

      @media (max-width: 1080px) {
        font-size: 8rem;
      }

      @media (max-width: 330px) {
        font-size: 7rem;
      }
    }

    .line-container {
      width: 45%;

      @media (max-width: 750px) {
        display: none;
      }
    }
  }
`

const query = graphql`
  query {
    line: file(relativePath: {eq: "psiconfort/line.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    clip: file(relativePath: {eq: "psiconfort/clip.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    fomentar: file(relativePath: {eq: "psiconfort/fomentar.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    proporcionar: file(relativePath: {eq: "psiconfort/proporcionar.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    crear: file(relativePath: {eq: "psiconfort/crear.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    insta: allInstaNode(limit: 3, filter: {mediaType: {eq: "GraphImage"}}) {
      edges {
        node {
          id
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
