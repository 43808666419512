import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import {useForm} from 'react-hook-form'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'
import {trackCustomEvent} from 'gatsby-plugin-google-analytics'
// Components
import Loader from '../components/Loader'

const validationSchema = Yup.object({
  firstName: Yup.string().required('Requerido'),
  lastName: Yup.string().required('Requerido'),
  email: Yup.string().email('Email inválido').required('Introduzca un email'),
})

export default function NewsletterModal({country, openModal, close}) {
  const {errors, getValues, handleSubmit, register, reset, setValue} = useForm({
    resolver: yupResolver(validationSchema),
  })
  const [showNotify, setShowNotify] = useState(false)
  const [loading, setLoading] = useState(false)
  const {pin, closeIcon} = useStaticQuery(query)

  const onSubmit = async values => {
    const isSpam = document.querySelector('input[name="phone"]').value !== ''
    if (isSpam) {
      throw Error('Fell into the trap')
    }
    if (country && !isSpam) {
      setLoading(true)
      trackCustomEvent({
        // string - required - The object that was interacted with (e.g.video)
        category: 'Newsletter Button',
        // string - required - Type of interaction (e.g. 'play')
        action: 'Click',
        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
        label: 'Newsletter',
        // number - optional - Numeric value associated with the event. (e.g. A product ID)
        value: 0,
      })

      const {email, firstName, lastName} = values

      const listFields = {
        FNAME: firstName,
        LNAME: lastName,
        COUNTRY: country,
      }

      addToMailchimp(email, listFields).then(({result, msg}) => {
        if (result === 'success') {
          setShowNotify('¡Solicitud enviada exitosamente!\n Revisa tu correo.')
        } else {
          if (/is already subscribed/i.test(msg)) {
            setShowNotify('Ya su correo está registrado.')
          } else if (/too many subscribe attempts/i.test(msg)) {
            setShowNotify('Error al procesar su registro. Intente en 5 minutos')
          } else if (/is an invalid email address/i.test(msg)) {
            setShowNotify('Este correo es inválido, intente con otro.')
          } else {
            setShowNotify('Hubo un error al enviar la solicitud.')
          }
        }
        setTimeout(() => {
          setShowNotify(false)
          close()
        }, 5000)
        setLoading(false)
      })
    }
  }

  const trimEmail = () => {
    setValue('email', getValues().email.trim(), {shouldDirty: true})
  }

  return (
    <NewsletterModalWrapper openModal={openModal}>
      <div className={`overlay ${openModal ? 'show' : ''}`}></div>
      <div className={`container ${openModal ? 'show' : ''}`}>
        <h2>¡suscríbete a mis correos!</h2>

        <div className="closeIcon" onClick={close}>
          <div className="pin-container">
            <Img fluid={pin.childImageSharp.fluid} alt="Pin" />
          </div>
          <div className="close-icon-container">
            <Img
              className="close"
              fluid={closeIcon.childImageSharp.fluid}
              alt="Icono de Cerrar"
            />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-group">
            <input
              type="text"
              name="firstName"
              placeholder="Nombre"
              ref={register}
            />
            <p className="errorMessage">
              {errors.firstName && errors.firstName.message}
            </p>
          </div>
          <div className="input-group">
            <input
              type="text"
              name="lastName"
              placeholder="Apellido"
              ref={register}
            />
            <p className="errorMessage">
              {errors.lastName && errors.lastName.message}
            </p>
          </div>
          <input
            type="hidden"
            name="phone"
            autoComplete="nope"
            tabIndex="-1"
            aria-hidden="true"
          />
          <div className="input-group">
            <input
              type="text"
              name="email"
              placeholder="Email"
              ref={register}
              onBlur={trimEmail}
            />
            <p className="errorMessage">
              {errors.email && errors.email.message}
            </p>
          </div>

          <button type="submit" disabled={!country}>
            {loading ? <Loader /> : 'suscribirme'}
          </button>
        </form>
      </div>
      <div className={`notify ${showNotify ? 'show' : ''}`}>{showNotify}</div>
    </NewsletterModalWrapper>
  )
}

const NewsletterModalWrapper = styled.nav`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 200;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;

  ${props =>
    props.openModal &&
    css`
      visibility: visible;
    `}

  .notify {
    background: #f5dcdc;
    color: black;
    padding: 1rem 2rem;
    position: fixed;
    bottom: 2rem;
    right: 3rem;
    border: 3px solid #c1abac;
    width: 28rem;
    text-align: center;
    transform: scale(0);
    transition: 0.3s;
    z-index: 205;

    &.show {
      transform: scale(1);
    }
  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
    z-index: -1;

    &.show {
      z-index: 200;
      visibility: visible;
      opacity: 1;
    }
  }

  .container {
    border-radius: 10px;
    position: relative;
    background: #f5e5e5;
    padding: 2rem 3rem;
    /* top: 50%;
    left: 50%; */
    display: flex;
    flex-direction: column;
    transition: 0.3s;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    width: 32rem;

    &.show {
      z-index: 201;
      visibility: visible;
      opacity: 1;
    }

    h2 {
      margin-bottom: 2rem;
    }

    .closeIcon {
      cursor: pointer;
      transition: 0.3s;

      &:active {
        opacity: 0.7;
      }

      @media (hover: hover) {
        &:hover {
          opacity: 0.7;
        }
      }

      .pin-container {
        position: absolute;
        width: 3.3rem;
        top: -5%;
        right: -5%;
      }

      .close-icon-container {
        position: absolute;
        width: 1rem;
        top: -2%;
        right: -2%;
      }
    }

    button {
      background: #fdacb8;
      border: none;
      padding: 1rem 3rem;
      font-family: 'Kinglet';
      font-size: 2rem;
      cursor: pointer;
      transition: 0.3s;
      margin-top: 1rem;
      width: 16rem;
      display: flex;
      justify-content: center;

      &:disabled {
        background: #ccc6c6;
        cursor: not-allowed;
      }

      &:active {
        opacity: 0.7;
      }

      @media (hover: hover) {
        &:hover {
          opacity: 0.7;
        }
      }
    }

    .input-group {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      input {
        width: 100%;
        background: transparent;
        padding: 1rem 0.7rem;
        border: none;
        border-bottom: 1px solid black;
        font-family: 'Comic Neue';
        font-size: 1.4rem;
      }

      select {
        width: 100%;
        background: transparent;
        height: 3.7rem;
        padding: 1rem 0.5rem;
        border: none;
        border-bottom: 1px solid black;
        font-family: 'Comic Neue';
        font-size: 1.4rem;
      }

      p.errorMessage {
        position: absolute;
        font-size: 1rem;
        color: red;
        line-height: 1.8rem;
        font-family: 'Comic Neue';
      }
    }
  }
`

const query = graphql`
  query {
    pin: file(relativePath: {eq: "about/pin.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    closeIcon: file(relativePath: {eq: "home/close_icon.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
