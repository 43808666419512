import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import {useStaticQuery, graphql} from 'gatsby'

export default function Footer() {
  const {facebook, instagram, pinterest, youtube} = useStaticQuery(query)

  return (
    <FooterWrapper>
      <div className="social">
        <a
          href="https://www.instagram.com/psiconfort"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="img-container">
            <Img
              fluid={instagram.childImageSharp.fluid}
              alt="Logo de Instagram"
            />
          </div>
        </a>
        <a
          href="https://www.pinterest.es/psiconfort/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="img-container">
            <Img
              fluid={pinterest.childImageSharp.fluid}
              alt="Logo de Pinterest"
            />
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UCA8LtPt3NIPOOPNABgb4gDQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="img-container">
            <Img fluid={youtube.childImageSharp.fluid} alt="Logo de YouTube" />
          </div>
        </a>
        <a
          href="https://www.facebook.com/pages/category/Community/PSICONFORT-280088095940619/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="img-container">
            <Img
              fluid={facebook.childImageSharp.fluid}
              alt="Logo de Facebook"
            />
          </div>
        </a>
      </div>
      <p>psiconfort 2020 | todos los derechos reservados&copy;</p>
      <p>
        contacto: <a href="mailto:hola@psiconfort.com">hola@psiconfort.com</a>
      </p>
      <p>
        desarrollado por{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://fonstack.dev"
        >
          fonstack.dev
        </a>
      </p>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  background: #63585d;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
  padding: 1.5rem;

  a {
    font-size: 1.4rem;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  .social {
    display: flex;
    margin-bottom: 1rem;

    a {
      transition: 0.2s;

      &:hover {
        opacity: 0.7;
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }

      .img-container {
        width: 4rem;
      }
    }
  }

  p {
    font-size: 1.4rem;
    letter-spacing: 1px;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    a {
      border-bottom: 1px solid white;
      transition: 0.2s;

      &:hover {
        letter-spacing: 2px;
        opacity: 0.8;
      }
    }
  }
`
const query = graphql`
  query {
    facebook: file(relativePath: {eq: "footer/facebook.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    instagram: file(relativePath: {eq: "footer/instagram.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    pinterest: file(relativePath: {eq: "footer/pinterest.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    youtube: file(relativePath: {eq: "footer/youtube.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
