import React from 'react'
import styled from 'styled-components'

export default function Loader() {
  return (
    <LoaderWrapper>
      <div className="lds-dual-ring"></div>
    </LoaderWrapper>
  )
}

const LoaderWrapper = styled.div`
  width: 2.3rem;
  height: 2.3rem;

  .lds-dual-ring {
    display: inline-block;
    width: 2.3rem;
    height: 2.3rem;
  }
  .lds-dual-ring:after {
    content: '';
    display: block;
    width: 2.3rem;
    height: 2.3rem;
    margin: 2px;
    border-radius: 50%;
    border: 0.3rem solid #675e5e;
    border-color: #675e5e transparent #675e5e transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
