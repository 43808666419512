import React from 'react'
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'

export default function About() {
  const {hearts, mariale, pin} = useStaticQuery(query)

  return (
    <AboutWrapper>
      <div className="container" id="about">
        <div className="pin-container">
          <Img fluid={pin.childImageSharp.fluid} alt="pin" />
        </div>
        <div className="pin-container">
          <Img fluid={pin.childImageSharp.fluid} alt="pin" />
        </div>
        <div className="title">
          Mariale
          <div className="hearts-container">
            <Img fluid={hearts.childImageSharp.fluid} alt="corazones" />
          </div>
        </div>
        <div className="info">
          <div className="profile-image-container">
            <Img
              className="profile-image"
              fluid={mariale.childImageSharp.fluid}
              alt="Mariale"
            />
          </div>
          <div className="text">
            <p>
              ¡Hola! Mi nombre es María Alejandra Sánchez, Licenciada en
              Psicología y fundadora de @Psiconfort. Soy venezolana, egresada de
              la Universidad Bicentenaria de Aragua con un Diplomado en
              Neuropsicología y preparación en Intervención en Crisis por
              Teléfono avalada por la Federación de Psicólogos de Venezuela.
            </p>
            <p>
              Gran parte de mi enfoque lo he estado compartiendo desde
              septiembre del 2018 a través de Psiconfort; un blog de contenido
              psicoeducativo que nació con el objetivo de promover la salud
              mental a lo largo del mundo y que, al día de hoy, cuenta con más
              de 580 mil seguidores provenientes de todos los rincones. En un
              mundo donde, según cifras oficiales, solo existen 3 psicólogos por
              cada 100.000 habitantes, la creación de Psiconfort es -y seguirá
              siendo- mi aporte para ayudar a derribar los prejuicios y miedos
              que aún existen alrededor de la terapia psicológica.
            </p>
            <p>Si llegaste hasta aquí, ¡Bienvenid@!</p>
          </div>
        </div>
      </div>
    </AboutWrapper>
  )
}

const AboutWrapper = styled.section`
  margin-top: 8rem;
  margin-bottom: 4rem;
  padding: 0 4rem;

  @media (max-width: 680px) {
    margin-top: 15rem;
  }

  .container {
    background: #dae4e5;
    padding: 4rem 8rem;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    position: relative;

    @media (max-width: 700px) {
      padding: 4rem 4rem;
    }

    @media (max-width: 500px) {
      padding: 4rem 2rem;
    }

    .pin-container {
      width: 4rem;
      position: absolute;
      top: -1.5rem;

      &:nth-child(1) {
        left: 2rem;
      }

      &:nth-child(2) {
        right: 1rem;
      }
    }

    .info {
      width: 100%;
      display: flex;

      @media (max-width: 700px) {
        flex-direction: column;
      }

      .profile-image-container {
        width: 30%;

        @media (max-width: 700px) {
          width: 100%;
        }

        .profile-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        width: 70%;
        background: #f6e2e3;
        padding: 6rem 5rem 4rem;
        text-align: center;

        @media (max-width: 700px) {
          width: 100%;
        }

        @media (max-width: 500px) {
          padding: 3rem 2rem;
        }

        p {
          &:not(:last-child) {
            margin-bottom: 2rem;
          }
        }
      }
    }

    .title {
      display: flex;
      font-size: 6rem;
      font-family: 'Kinglet';
      color: #3a3737;
      margin-bottom: 2rem;

      .hearts-container {
        width: 4rem;
      }
    }
  }
`

const query = graphql`
  query {
    pin: file(relativePath: {eq: "about/pin.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    hearts: file(relativePath: {eq: "about/hearts.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mariale: file(relativePath: {eq: "about/mariale.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
