import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'
// Componentes
import NavbarSVG from '../svgs/tbn.svg'
import NewsletterModal from '../components/NewsletterModal'

export default function Navbar(props) {
  const {hamburgerIcon, closeIcon} = useStaticQuery(query)
  const [navOpen, setNavOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <NavbarWrapper>
        <div className="links">
          <a href="#home">inicio</a>
          <a href="#about">sobre mí</a>
          <a href="#psiconfort">psiconfort</a>
          <a href="#therapy">terapia online</a>
        </div>
        <div className="newsletter" onClick={() => setOpenModal(true)}>
          <NavbarSVG />
          <p>newsletter</p>
        </div>
        <div className="hamburger" onClick={() => setNavOpen(true)}>
          <Img
            fluid={hamburgerIcon.childImageSharp.fluid}
            alt="Ícono Abrir Menú"
          />
        </div>
        <NewsletterModal
          {...props}
          openModal={openModal}
          close={() => setOpenModal(false)}
        />
      </NavbarWrapper>
      <BurgerNavWrapper open={navOpen}>
        <div className="exit-hamburger" onClick={() => setNavOpen(false)}>
          <Img
            fluid={closeIcon.childImageSharp.fluid}
            alt="Ícono Cerrar Menú"
          />
        </div>
        <div className="link-container">
          <a onClick={() => setNavOpen(false)} className="link" href="#home">
            inicio
          </a>
          <a onClick={() => setNavOpen(false)} className="link" href="#about">
            sobre mí
          </a>
          <a
            onClick={() => setNavOpen(false)}
            className="link"
            href="#psiconfort"
          >
            psiconfort
          </a>
          <a onClick={() => setNavOpen(false)} className="link" href="#therapy">
            terapia online
          </a>
        </div>
      </BurgerNavWrapper>
    </>
  )
}

const NavbarWrapper = styled.nav`
  width: 100%;
  padding: 1.3rem 6rem;
  border-bottom: 1px solid #675e5e;
  display: flex;
  justify-content: space-between;
  font-family: 'Kinglet';
  font-size: 2.2rem;
  color: #675e5e;

  @media (max-width: 850px) {
    padding: 1.3rem 3rem;
  }

  .hamburger {
    position: absolute;
    top: 2rem;
    right: 5%;
    display: none;
    z-index: 15;
    cursor: pointer;
    width: 3.6rem;

    &:active {
      div {
        opacity: 0.8;
      }
    }

    @media (max-width: 850px) {
      display: block;
    }
  }

  .links {
    display: flex;
    align-items: center;

    @media (max-width: 850px) {
      display: none;
    }

    a {
      transition: 0.1s;

      &:hover {
        color: #e2c6c8;
      }

      @media (hover: none) {
        &:active {
          color: #e2c6c8;
        }
      }

      &:not(:last-child) {
        margin-right: 7rem;
      }
    }
  }

  .newsletter {
    position: relative;
    cursor: pointer;

    &:hover {
      path {
        fill: #ffced6;
      }
    }

    @media (hover: none) {
      &:active {
        path {
          fill: #ffced6;
        }
      }
    }

    svg {
      height: 4.5rem;

      path {
        transition: 0.2s;
      }
    }

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

const BurgerNavWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: #dedede;
  z-index: 50;
  opacity: 1;
  visibility: hidden;
  transition: 0.4s;
  transform: translateX(100%);

  ${props =>
    props.open &&
    css`
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    `}

  .exit-hamburger {
    position: absolute;
    width: 3.6rem;
    height: 3.1rem;
    top: 3vh;
    right: 5%;

    &:active {
      div {
        opacity: 0.7;
      }
    }
  }

  .link-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%);
    text-align: center;
    top: 50%;
    left: 50%;

    .link {
      color: #675e5e;
      font-size: 4rem;
      letter-spacing: 0.3rem;
      transition: 1s;
      transform: scale(0);
      display: inline-block;
      font-family: 'Kinglet';

      &:active {
        opacity: 0.6;
        letter-spacing: 5px;
      }

      ${props =>
        props.open &&
        css`
          transform: scale(1);
        `}

      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }
`

const query = graphql`
  query {
    hamburgerIcon: file(relativePath: {eq: "home/hamburger_icon.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    closeIcon: file(relativePath: {eq: "home/close_icon.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
