import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'
import NoteButtonSVG from '../svgs/note_button.svg'

export default function Home() {
  const {slogan} = useStaticQuery(query)
  return (
    <HomeWrapper>
      <div className="img-container">
        <Img fluid={slogan.childImageSharp.fluid} alt="Slogan: " />
      </div>
      <a
        href="https://psiconfort.as.me/?utm_source=landing_page&utm_medium=initial_cta&utm_campaign=landing_carlos"
        className="note_button"
      >
        <NoteButtonSVG />
        <p>¡Quiero una cita!</p>
      </a>
    </HomeWrapper>
  )
}

const HomeWrapper = styled.section`
  padding: 4rem 5rem;
  position: relative;

  @media (max-width: 680px) {
    padding: 3rem 2rem;
  }

  .img-container {
    display: block;
    width: 85%;
    margin-left: auto;

    @media (max-width: 680px) {
      width: 98%;
      margin: 0 auto;
    }
  }

  .note_button {
    position: absolute;
    margin-top: -8rem;
    cursor: pointer;

    @media (max-width: 680px) {
      left: 50%;
      transform: translateX(-50%);
      margin-top: 3rem;
    }

    &:hover {
      path {
        opacity: 0.7;
      }

      p {
        letter-spacing: 1px;
      }
    }

    @media (hover: none) {
      &:active {
        path {
          fill: #ffced6;
        }
      }
    }

    svg {
      width: 30rem;
      height: auto;

      @media (max-width: 900px) {
        width: 24rem;
      }

      path {
        transition: 0.3s;
      }
    }

    p {
      width: 100%;
      text-align: center;
      position: absolute;
      font-family: 'Kinglet';
      font-size: 3.4rem;
      color: #675e5e;
      z-index: 10;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s;

      @media (max-width: 900px) {
        font-size: 3rem;
      }
    }
  }
`

const query = graphql`
  query {
    slogan: file(relativePath: {eq: "home/slogan.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
