import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'

export default function OnlineTherapy() {
  const {
    autoestima,
    calendario,
    crecimiento,
    decisiones,
    depresion,
    estres,
    linea,
    linea2,
    linea3,
    manos,
    persona,
    rupturas,
    telefono,
  } = useStaticQuery(query)

  return (
    <OnlineTherapyWrapper>
      <div className="benefits-container" id="therapy">
        <div className="header">
          <div className="line-container">
            <Img fluid={linea.childImageSharp.fluid} alt="Línea" />
          </div>
          <h2 className="title">beneficios de la terapia online</h2>
          <div className="line-container">
            <Img fluid={linea.childImageSharp.fluid} alt="Línea" />
          </div>
        </div>
        <div className="container">
          <div className="benefit">
            <div className="benefit-img-container">
              <Img fluid={telefono.childImageSharp.fluid} alt="Teléfono" />
            </div>
            <p>
              <span>Accesible</span>
              <br />
              Sólo necesitas estar en un lugar privado en el que te sientas
              cómodo.
            </p>
          </div>
          <div className="benefit">
            <div className="benefit-img-container">
              <Img fluid={calendario.childImageSharp.fluid} alt="Calendario" />
            </div>
            <p>
              <span>Flexible</span>
              <br />
              Se adapta a tus días y horarios disponibles.
            </p>
          </div>
          <div className="benefit">
            <div className="benefit-img-container">
              <Img fluid={manos.childImageSharp.fluid} alt="Manos" />
            </div>
            <p>
              <span>Segura</span>
              <br />
              Con alto nivel de confidencialidad resguardando tu información.
            </p>
          </div>
          <div className="benefit">
            <div className="benefit-img-container">
              <Img fluid={persona.childImageSharp.fluid} alt="Persona" />
            </div>
            <p>
              <span>Continua</span>
              <br />
              Aunque viajes o te muevas de lugar, podrás tener un continuo
              seguimiento en tu terapia.
            </p>
          </div>
        </div>
      </div>
      {/* Can I help you? */}
      <div className="canhelp-container">
        <div className="header">
          <h2 className="title">áreas de atención</h2>
          <div className="line2-container">
            <Img fluid={linea2.childImageSharp.fluid} alt="Línea" />
          </div>
        </div>
        <div className="container">
          <div className="attention-area-img-container">
            <Img fluid={estres.childImageSharp.fluid} alt="Nube lloviendo" />
          </div>
          <div className="attention-area-img-container">
            <Img fluid={rupturas.childImageSharp.fluid} alt="Corazón roto" />
          </div>
          <div className="attention-area-img-container">
            <Img
              fluid={depresion.childImageSharp.fluid}
              alt="Persona dibujada en negro"
            />
          </div>
          <div className="attention-area-img-container">
            <Img
              fluid={autoestima.childImageSharp.fluid}
              alt="Corazón tejido"
            />
          </div>
          <div className="attention-area-img-container">
            <Img
              fluid={decisiones.childImageSharp.fluid}
              alt="Anotaciones en cuaderno con bolígrafo"
            />
          </div>
          <div className="attention-area-img-container">
            <Img
              fluid={crecimiento.childImageSharp.fluid}
              alt="Gráfico de barras con tendencia creciente"
            />
          </div>
        </div>
      </div>
      {/* When to go to therapy */}
      <div className="whentogo-container">
        <div className="header">
          <h2 className="title">¿cuándo ir a terapia?</h2>
          <div className="line2-container">
            <Img fluid={linea2.childImageSharp.fluid} alt="Línea" />
          </div>
        </div>
        <div className="container">
          <div className="tip">
            <div className="title">
              <p>1</p>
              <div className="line3-container">
                <Img fluid={linea3.childImageSharp.fluid} alt="Línea" />
              </div>
            </div>
            <p className="text">
              Cuando las circunstancias excedan tus recursos personales para
              afrontarlas.
            </p>
          </div>
          <div className="tip">
            <div className="title">
              <p>2</p>
              <div className="line3-container">
                <Img fluid={linea3.childImageSharp.fluid} alt="Línea" />
              </div>
            </div>
            <p className="text">
              Cuando el malestar emocional afecte tu desenvolvimiento en las
              actividades de la vida diaria.
            </p>
          </div>
          <div className="tip">
            <div className="title">
              <p>3</p>
              <div className="line3-container">
                <Img fluid={linea3.childImageSharp.fluid} alt="Línea" />
              </div>
            </div>
            <p className="text">
              Cuando necesites un espacio para ser escuchado y comprendido.
            </p>
          </div>
          <div className="tip">
            <div className="title">
              <p>4</p>
              <div className="line3-container">
                <Img fluid={linea3.childImageSharp.fluid} alt="Línea" />
              </div>
            </div>
            <p className="text">
              Cuando requieras una orientación o guía ante una situación en
              particular.
            </p>
          </div>
          <div className="tip">
            <div className="title">
              <p>5</p>
              <div className="line3-container">
                <Img fluid={linea3.childImageSharp.fluid} alt="Línea" />
              </div>
            </div>
            <p className="text">
              Cuando desees mejorar un área específica de tu vida. Recuerda:
              ¡Todo malestar emocional es importante!
            </p>
          </div>
        </div>
      </div>
    </OnlineTherapyWrapper>
  )
}

const OnlineTherapyWrapper = styled.section`
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 0 3rem;

  @media (max-width: 550px) {
    padding: 0;
  }

  .whentogo-container {
    padding: 0 2rem;

    .container {
      margin-top: 4rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: 1000px) {
        justify-content: center;
      }

      .tip {
        width: 19%;
        background: #f5dcdc;
        padding: 2.5rem;

        @media (max-width: 1000px) {
          width: 30rem;
          margin-bottom: 3rem;
          margin-right: 2rem;
        }

        @media (max-width: 620px) {
          margin-right: 0;
        }

        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 6rem;
          font-family: 'Kinglet';
          margin-bottom: 1rem;
          color: #434343;

          .line3-container {
            width: 80%;
            margin-left: 1rem;
          }
        }
      }
    }

    .header {
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .line2-container {
        height: 1.5rem;
        margin-top: 0.4rem;
      }

      h2.title {
        font-family: 'Kinglet';
        letter-spacing: 2px;
        font-weight: 100;
        font-size: 4rem;
        color: #524c4c;
      }
    }
  }

  .canhelp-container {
    .container {
      padding: 0 5rem;
      margin-top: 4rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: 700px) {
        justify-content: center;
        align-items: center;
      }

      .attention-area-img-container {
        width: 26%;
        height: 100%;
        margin-bottom: 3rem;

        @media (max-width: 700px) {
          width: 44%;

          &:nth-child(odd) {
            margin-right: 3rem;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .header {
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .line2-container {
        width: 31rem;
        margin-top: 0.4rem;
      }

      h2.title {
        font-family: 'Kinglet';
        letter-spacing: 2px;
        font-weight: 100;
        font-size: 4rem;
        color: #524c4c;
      }
    }
  }

  .benefits-container {
    .container {
      background: #f8f8f8;
      width: 85%;
      margin: 0 auto;
      margin-top: 4rem;
      padding: 4rem 4rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 550px) {
        padding: 3rem 3rem 0;
      }

      .benefit {
        display: flex;
        align-items: center;
        width: 48%;
        margin-bottom: 3rem;

        @media (max-width: 700px) {
          width: 100%;
        }

        .benefit-img-container {
          min-width: 8rem;
          margin-right: 3rem;

          @media (max-width: 550px) {
            margin-right: 1.5rem;
          }
        }

        p {
          font-size: 1.8rem;

          span {
            font-weight: 900;
          }
        }
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 560px) {
        justify-content: center;
      }

      .line-container {
        width: 17%;

        @media (max-width: 800px) {
          width: 20%;
        }

        @media (max-width: 560px) {
          display: none;
        }
      }

      h2.title {
        font-family: 'Kinglet';
        background: #fdacb8;
        letter-spacing: 2px;
        font-weight: 100;
        font-size: 5.5rem;
        padding: 1rem 4rem;
        color: #524c4c;
        text-align: center;
        margin: 0 2rem;

        @media (max-width: 650px) {
          font-size: 5rem;
        }
      }
    }
  }
`

const query = graphql`
  query {
    estres: file(relativePath: {eq: "therapy/help/estres.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    rupturas: file(relativePath: {eq: "therapy/help/rupturas.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    depresion: file(relativePath: {eq: "therapy/help/depresion.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    autoestima: file(relativePath: {eq: "therapy/help/autoestima.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    decisiones: file(relativePath: {eq: "therapy/help/decisiones.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    crecimiento: file(relativePath: {eq: "therapy/help/crecimiento.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    linea: file(relativePath: {eq: "psiconfort/line.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    linea2: file(relativePath: {eq: "therapy/line2.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    linea3: file(relativePath: {eq: "therapy/when/line3.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    telefono: file(relativePath: {eq: "therapy/telefono.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    calendario: file(relativePath: {eq: "therapy/calendario.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    manos: file(relativePath: {eq: "therapy/manos.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    persona: file(relativePath: {eq: "therapy/persona.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
